import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-confirm-dialog',
  templateUrl: './delete-confirm-dialog.component.html',
})
export class DeleteConfirmDialogComponent {
  public form: FormGroup;

  public objectType = ''
  public objectName = ''

  public header = ''
  public message = ''
  public inputLabel = ''
  public confirmText = 'Permanently Delete'
  public buttonLabel = 'Delete'

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {
    this.objectType = data.objectType ?? 'data';
    this.objectName = data.objectName ?? '';
    this.confirmText = data.confirmText ?? 'Permanently Delete';
    this.header = data.header ?? `Delete ${this.objectType}`
    this.message = data.message ?? `Are you sure you want to delete the ${this.objectType} ${this.objectName}?`
    this.inputLabel = data.inputLabel ?? `Type "${this.confirmText}" below to delete the ${this.objectType}.`
    this.buttonLabel = data.buttonLabel ?? 'Delete'
    this.initForm();
  }

  initForm() {
    const pattern = new RegExp(`^${this.confirmText}$`)
    this.form = this.fb.group({
      confirm: ['', [Validators.required, Validators.pattern(pattern)]]
    });
  }

  onCancel(): void {
    this.initForm();
    this.dialogRef.close();
  }

  onSubmit() {
    if(this.form.value.confirm === this.confirmText){
      return this.dialogRef.close(true);
    }
    return
  }
}
