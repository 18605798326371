<form [formGroup]='form' (ngSubmit)="onSubmit()" id="delete-confirm-form">
  <h2 mat-dialog-title>{{header}}</h2>
  <p>{{message}}</p>
  <mat-dialog-content>
    <mat-form-field appearance='outline'>
      <mat-label>{{inputLabel}}</mat-label>
      <input matInput formControlName='confirm' required id="confirm-dialog-input">
      <mat-error *ngIf="this.form.controls.confirm.hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="this.form.controls.confirm.hasError('pattern')">
        Input needs to be "{{confirmText}}"
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button color='primary' type="button" id='btn-cancel-delete' (click)='onCancel()'>
      CANCEL
    </button>
    <button mat-raised-button type="submit" color='primary' id='btn-confirm-delete'>
      {{buttonLabel}}
    </button>
  </mat-dialog-actions>
</form>
