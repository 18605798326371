<footer class="footer">
  <div>
    <p>
      © {{ currentYear }} InterSystems Corporation.
      <span *ngFor="let link of supportLinks">
        <a [href]="link.url" rel="noopener noreferrer" target="_blank">{{ link.title }}.</a>
      </span>
    </p>
  </div>
</footer>
